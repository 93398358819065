   a.download-pdf {
        padding: 30px;
        background-color: #f7f8f9;
        border-radius: 10px;
        margin-top: 50px;
         display: block;
        h4 {
            font-size: 1.1rem;
            font-weight: 700;
            color: #1d1d25;
            margin-bottom: 30px;
        }

      .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: #1d1d25;
  
            .pdf-icon {
                margin-right: 10px;
                font-size: 2rem;
            }

            .download {
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }