@mixin fl-btwn-centr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin fl-concetr-centr {
    display: flex;  
    justify-content: center;     
    align-items: center;
}
@mixin fl-clum {
    display: flex;        
    flex-direction: column;
}
@mixin fl-btw {
    display: flex;   
    justify-content:space-between;   
}


// ====  mapConnect section start =====
.mapConnect-section{
	background-color: rgba(255, 255, 255, 1);
	.container{
		padding-bottom: 80px;
		.map-connect{
			width: 100%;
	        height: auto;
			display: flex;
			flex-wrap: wrap;
			gap: 2rem;
			.connect{
				flex: 1;
			.contact-adress{
				display: flex;
				gap: 1rem;
			.adress-icon, .tel-icon,.email-icon{
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 44px;
				border-radius: 100%;
				background-color: rgba(1, 49, 131, 1);
				color: rgba(255, 255, 255, 1);
			}
			.number,
			.street,
			.nt-email,
		     h6	{
				font-size: 1em;
				font-weight: 500;
				padding-top: 18px;
			   color: black;
			   word-wrap: break-word;
			}
			.adress,.call,.email{
				flex: calc(100%/3);
				max-width: calc(100%/3);
				background-color: rgba(245, 248, 250, 1);
				@include fl-clum();
				padding: 2em 0 3em 0.7em;
			}	
			}	
			.line-call-send{
				display: block;
				margin: 32px 0px;
			   border: 1px solid rgba(223, 236, 244, 1);
			   transition: 0.2s ease;
			   cursor: pointer;
			   &:hover{
				border: 1px solid rgb(8, 152, 241);
			   }
			}
			.send-question{
				// which are common to this section
			
				label{
					display: block;
					font-weight: 600;
					font-size: 13px;
					color: rgba(11, 11, 15, 1);
					margin-bottom: 8px;
				}
				input{
					width: 100%;
					margin-bottom: 20px;
					border-radius: 8px;
					padding: 12px;
					border: 1px solid 	rgba(223, 236, 244, 1);
					font-size: 15px;
					font-weight: 500;
					color: rgba(11, 11, 15, 1);
					
				&:focus {
					outline: none;
					  }
				}
				.error{
					color: rgba(255, 0, 0, 1);
					margin: -15px auto 20px;
					font-size: 17px;
					font-weight: 500;
					overflow: hidden;
					 }

				h3{
					font-size: 32px;
					font-weight: 600;
					margin-bottom: 32px;
				}
			.name-surname{
				@include fl-btw();
				gap: 2rem;
			.name,
			.surname{
				width: 100%;
			}	
			}
			.send-btn{
				width: 100%;
				border: none;
				border-radius: 8px;
				padding: 18px 16px;
				color: rgba(255, 255, 255, 1);
				background-color: rgba(1, 49, 131, 1);
				font-size: 15px;
				font-weight: 500;
				cursor: pointer;
			&:hover{
				background-color: rgba(1, 49, 131, 0.8);;
			}	
			}	
			}
			}

			.map{
				flex: 1;
			}
		}
	}
}




// google maps default style 
.gmnoprint{
	display: none;
}
.gm-fullscreen-control{
	display: none;
}

// number (telefon) inputun css
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

// ====  mapConnect section responsive start =====

@media screen and (max-width:950px) {
	.mapConnect-section{
		background-color: rgba(255, 255, 255, 1);
		position: relative;
		.container{
			padding-bottom: 80px;
			width: 94%;
			.map-connect{
				.connect{
					flex: 1 1 100%;
					width: 100%;
					.contact-adress{
					.number,
					.street,
					.nt-email,
					 h6	{
						font-size: 0.7em;
					}
					}
				}
	
				.map{
					width: 100%;
					min-height: 36rem;
				
				}
			}
		}
	}
}