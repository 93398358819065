.partnyors-products-section {
	margin-bottom: 16px;
	background-color: rgba(255, 255, 255, 1);
	.container {
		padding-bottom: 0;
		h4 {
			margin-bottom: 20px;
			font-size: 20px;     
			font-weight: 600;
			letter-spacing: 2px;
			color: rgba(11, 11, 15, 1);
		}


	}
}

@media screen and (max-width:950px) {
	.partnyors-products-section {
		.container {
			padding-bottom: 0;
			width: 93%;
		}
	}
}