@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

// ====  EquipmentSectionSlide  section start =====

.slider-section{
.container{	
	width: 95%;
	padding-top: 30px;
 .slider-div{    
  max-width: 99% !important;
  margin: 0 auto;
  display: flex !important;
  justify-content: space-between;
  height: 70vh;
//   a{
// 	display: inline-block;
.info{
	background-color:rgba(33, 43, 55, 1);
	color: rgba(255, 255, 255, 1);
    width: 30%; 
	border-radius: 8px;
	margin-right: 12px;
	display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
	text-align: center;
	
h3{
	font-size: 30px;
	font-weight: 500;
	margin: 0px 10px;
}	
 p{
	margin: 28px 10px;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 25px;
 }	
.detailet-btn{
	padding: 10px 21px;
	font-size: 15px;
	font-weight: 600;
	border-radius: 27px;
	border: none;
	cursor: pointer; 
	transition: 1s ease-in-out;
  &:hover{
	background-color:rgb(1, 49, 131);
	color: white;

  }	
}
	
}
//  }
.image{
   width: 70%;
	img{
		width: 100%;
		height: 100%;
		border-radius: 8px;
		object-fit: cover;
	}
}

}

}
}



//  slick slider button
.slick-next,.slick-arrow  {
	display: none !important;
	// background-color: transparent;
	// color: transparent;

}

// ========  // ====  EquipmentSectionSlide  section responsive  =====
@media screen and (max-width: 950px) {
	.slider-section{
		.container{	
			width: 98%;
			padding: 10px;
		 .slider-div{
		  max-width: 98% !important;
		  height: auto;
		display: flex;
		flex-direction: column;
		.info{
			width: 100%; 
			height: 300px;
			margin-bottom: 20px;
		h3{
			margin: 20px auto;
		}	
		.detailet-btn{
			margin-bottom: 30px;
		}
			
		}
		.image{
		   width: 100%;
		   height: 300px;
		}
		
		}
		
		}
		}
		
}
