
//  ============   projects section start ===============
#projects {
	background-color: rgba(255, 255, 255, 1);
	position: relative;
    margin-top: 50px;    
	scroll-margin-top: 4rem;
	.container {
		width: 100%;
		display: flex;

		.all-project-see {
			margin-left: 39px;      
		    max-width: 16%;    
			width: 100%;
			background-image: url("../../assets/projectBGimage/Group\ \(1\).png");
			background-repeat: no-repeat;
			background-size: cover;
			background-color: rgba(33, 43, 55, 1);
			color: rgba(255, 255, 255, 1);
			border-radius: 15px;
            display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: 10px;
			padding: 0.7em;   
			h3 {
				font-size: 1.7em;
				margin-bottom: 0.2em;
				text-align: center;
			}
			p {
				font-size: 1em;
				font-weight: 500;
			}
		}

		.slider-slick-part {
	          width: 83%; 
			.slick-slider {
				.slick-arrow {
					display: none !important;
				}

				.slick-track {
					display: flex;
					justify-content: center;

					.project {
					    	margin-left: 2.2rem;         
						.project-img {  
							border-radius: 15px;
							margin-left: 1.5rem;  
							position: relative;
							transition: 0.5s ease-in-out;
							cursor: grab;
							overflow: hidden;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
                                transition: 0.5s ease-in-out;  
							 }
							&:hover{
								img{
								transform: scale(1.2);
								transition: 0.5s ease-in-out;
								}
							}	
						}

						.overlay {
							position: absolute;
							width: 100%;
							height: 100%;
							background-color: rgba(0, 0, 0, 0.3);   
							top: 0;
							left: 0;
							transition: 0.5s ease;
							display: flex;
						    align-items: end;
							margin-bottom: 1rem;

							h4 {
								position: absolute;
								font-style: normal;
								font-size: 1rem;
								padding: 1em;
								font-weight: 600;
								width: 100%;
								color: rgba(255, 255, 255, 1);

							}

						}

					}
				}
			}
		}
	}

}

// ==========     projects section section responsive  ============== 

 @media screen and (max-width: 1440px) {
	#projects {
		.container {
			.all-project-see {
				max-width: 25%;    
				padding: 0.7rem;   
				h3 {
					font-size: 1em;
				}
				p {
					font-size: 0.7em;
				}
			}
	
			.slider-slick-part {
				  width: 75%;
				.slick-slider {
					.slick-track {
						.project {
							.overlay {
								h4 {
									font-size: 0.7rem;
									font-weight: 500;
								}
	
							}
	
						}
					}
				}
			}
		}
	}
 }

 @media screen and (max-width:950px) {
	#projects {
		margin-top: 10px;
		.container {
			flex-wrap: wrap;
			gap: 1rem;
			width: 94%;
			.all-project-see {
				 max-width: 100%;     
			     min-height: 10rem;   
				 margin: 0;
				h3 {
					font-size: 1em;
					margin-bottom: 1rem;
				}
				p {
					font-size: 0.8em;
				}
			}
	
			.slider-slick-part {    
				  width: 103%;
				.slick-slider {
					.slick-track {
						.project {
							margin: 0;
							.project-img {  
								&:hover{
									img{
									transform: inherit;
									}
								}	
							}
							.overlay {
								h4 {
									font-size: 0.7rem;
									font-weight: 500;
								}
	
							}
	
						}
					}
				}
			}
		}
	}
 }