@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;    
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

// ====  Footer section start =====
.footer-section {
  background-color: rgba(1, 49, 131, 1);
	height: 184px;
	.container {
		.footer {
			width: 100%;
		
			color: rgba(255, 255, 255, 1);

			.footer-up {
				@include fl-btwn-centr();

				.logo {
					height: 44px;

					img {
						height: 100%;
					}
				}

				.pages-adress-call {  
					

					@include fl-btw();
					width: 65%;

					.pages {
						display: flex;

						li {
							margin: auto 20px;
							a {
								color: rgba(255, 255, 255, 1);
								font-weight: 600;
								font-size: 11px;
								letter-spacing: 1px;
								
							}
						}
					}
            .adress-call{
			@include fl-clum();
			align-items: flex-end;
				li{
					font-weight: 500;
					font-size: 11px;
		        	margin-bottom: 4px;
					@include fl-concetr-centr();
					.location-icon{
						font-size: 20px;
						margin-left: 10px;
					}}
					a{
						color: rgba(255, 255, 255, 1);
						font-weight: 600;
						font-size: 11px;
						@include fl-concetr-centr();
					.call-icon{
						font-size: 20px;
						margin-left: 10px;
					}	
					}
				}
			}
				}
			}

.line{
	display: block;
	margin: 15px auto;
	background-color: rgba(71, 103, 157, 1);
}
			.footer-down {
				@include fl-btw();
			p{
				font-size: 11px;
				font-weight: 400;
			}	
			.social{
				display: flex;
				justify-content: space-between;
				li>a{
					display: inline-block;
					width: 16px;
					height: 16px;
					margin: auto 12px;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
			}
		}
	}

// ====  Footer section responsive start =====	

@media screen and (max-width:950px) {
	.footer-section {
		height: auto;
		  .container {
			width: 94%;
			padding-top: 0.8rem;
			padding-bottom: 0.4rem;
			  .footer {
				  width: 100%;
				  .footer-up {
					  flex-direction: column;
					  justify-content: inherit;
					  align-items: self-start;
					  .logo {
						height: 44px;
						object-fit: cover;
					  }
	  
					  .pages-adress-call {  
						margin-top: 15px;
						 width: 100%;
						  .pages {
							 flex-direction: column;
							  li {
								  margin:2px 0;
							  }
						  }
				  .adress-call{
					  li{
						font-weight: 400;
						font-size: 0.8rem;
						}
						  a{
 							   font-weight: 400;
					        	font-size: 0.8rem;
						  .call-icon{
							  font-size: 17px;
						  }	
						  }
					  }
				  }
					  }
				  }
	
				  .footer-down {
					flex-direction: column;
					align-items: center;
				
				  .social{
					  li>a{
						  margin: 11px 7px;
						
					  }
				  }
				  }
			  }
		  }
}    



