

.not-found-section{
	.container{
		padding: 0;
		  
		.not-found{
			width: 100%;
			min-height: calc(100vh - 184px - 72px);  
			display: flex;
			flex-direction: column;
			justify-content: center;  
			align-items: center;
      img{
		width: 100%;
		height: 20vh;
	  }
		  a{
			padding: 1rem 2rem;
			background-color: blue;
			color: white;
			border-radius: 20px;
			font-size: 1rem;
			font-weight: 600;
		  }
		}
	}
}