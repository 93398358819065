
//=====================  about section style start   =============================
.about-section{
	margin-top: 3rem;
	.container{
       padding: 0;
	.about-download{
		display: flex;
		justify-content: space-between;
		position: relative;
		align-items: flex-start;
		.download{
			width: 20%;
			position: sticky;
			top: 90px;
			left: 0;
			z-index: 1;
			h3{
				padding: 30px;
				background-color: #f7f8f9;
				border-radius: 10px;
			 }
			
		}
		.about{
			width: 75%;
		.about-content{
			display: flex;
			flex-direction: column;
		.about-header{
			background-color: #f7f8f9;
			padding: 30px;
			border-radius: 10px;
			.about-header-image{
				img{
					width: 100%;
					// height: 50vh;
				}
			 }	
			h2{
			  text-align: center;
               margin: 2rem auto;
               font-size: 2rem;
			   font-weight: 600;
			}
			p{
				font-size: 19px;
                font-weight: 200;
                line-height: 30px;
				text-align: justify;
			}
       		
		}	
		}
        .about-activity{
			margin: 50px auto -70px;  
			background-image: url("../../assets/aboutSectionBGimage&NTlicense/construction-site-silhouettes\ \(1\).png");
			background-repeat: no-repeat;
			background-size: cover;
			height: 180px; 
			display: flex;
			justify-content: space-around;
			align-items: center;
			p{
				color: white;
				font-size: 0.5rem;   
				font-weight: 500;
			}
			h4{ color: white;
				font-size: 1.5rem;
				font-weight: 600;
				margin-bottom: .2rem;
			}
			
		}

		.license{
			    padding: 150px;    
				display: flex;
				flex-wrap: wrap;
				gap: 2rem;
			.license-image{
				margin: 0 auto;
				min-width: 14rem;
				flex: 1 0 calc(100% / 2 - 2rem);  
			img{
				width: 100%;
				box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
			}	
			}
		}
	   .map-connect{
			width: 100%;
			margin: -70px auto 50px;
			.container {
				width: auto;
			}
	   }
	}   
	}
}
}

//=====================  about section responsive start   =============================
@media screen and (max-width:950px) {
	.about-section{
		margin: 3rem auto;
		.container{
		   padding: 0;
		   width: 94%;  
		.about-download{
			flex-direction: column-reverse;
			.download{
				display: none;
				// width: 100%;
				// text-align: center;
				// button{
				// 	margin: 0 auto;
				//  }
				// h3{
				// 	display: none;
				//  }
			}
			.about{
				width: 100%;
			.about-content{
				display: flex;
				flex-direction: column;
			.about-header{
				background-color: #f7f8f9;
				padding: 30px;
				border-radius: 10px;
				.about-header-image{
					img{
						width: 100%;
						// height: 30vh;
					}
				 }	
				h2{
				  text-align: center;
				   margin: 2rem auto;
				   font-size: 2rem;
				   font-weight: 600;
				}
				p{
					font-size: 19px;
					font-weight: 200;
					line-height: 30px;
					text-align: justify;
				}
				   
			}	
			}
			.about-activity{
				margin: 50px auto 0;  
				height: 280px; 
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				p{
					color: white;
					font-size: 0.75rem;
					font-weight: 500;
					margin-bottom: 30px;     
				}
				h4{ color: white;
					font-size: 1.25rem;
					font-weight: 600;
					margin-bottom: .2rem;
				}
				
			}
	
			.license{
					padding: 10px;  
					margin: 30px auto;  
			}
		   .map-connect{
			width: 100%;
			margin: 0 auto 50px;
		   }
		}   
		}
	}
	}
	
}