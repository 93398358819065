@mixin fl-btwn-centr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin fl-concetr-centr {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin fl-clum {
    display: flex;
    flex-direction: column;
}

@mixin fl-btw {
    display: flex;
    justify-content: space-between;
}

// ==================  systems section start  =========
.systems-section {
    min-height: 80vh;  
    .container {
        .systems {
          @include fl-btw();
            width: 100%;
            position: relative;
            align-items: flex-start;
        .systems-list-download{  
            position: sticky;
            top: 80px;
            z-index: 1;
            .systems-list {
                border-radius: 10px;
                background-color: #f7f8f9;  
                padding: 30px;
               
                a:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                  }
                  
                h4 {
                    font-size: 1.1rem;
                    font-weight: 700;
                    color: #1d1d25;
                    margin-bottom: 30px;
                }

                .list-a {
                    display: block;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #27292a;
                    color: black;
                    &:hover {
                        color: #717e8b;

                    }

                    .icon-title {
                        display: flex;
                        align-items: center;
                        .title {
                            font-size: 0.9rem;
                            font-weight: 600;
                            margin-left: 20px;
                        }
                    }
                }
          

                .active {
                    color: rgb(75, 75, 203);
                   &:hover{
                        color: rgb(75, 75, 203);
                    }
                }

            }
            }    
            // ============= descrption  =======
            .description {
                width: 80%;
                margin-left: 30px;
                img {
                    width: 100%;
                    border-radius: 8px;
                    // object-fit: contain;
                    height: 25rem;
                    object-fit: cover;
                }

                h4 {
                    text-align: center;
                    margin: 20px auto;
                    font-size: 20px;
                }

                p {
                    font-size: 19px;
                    font-weight: 200;
                    line-height: 30px;
                }
            }
        }
    }
}


// ==================  systems section responsive start  =========

@media screen and (max-width:950px) {
    .systems-section {
        .container {
            padding-top: 20px;
            width: 95%;
            .systems {
            flex-wrap: wrap;
            .systems-list-download{  
                  width: 100%;
                  height: auto;
                  position: inherit;
                  top: 0;
                .systems-list {
                    h4 {
                        margin-bottom: 10px;
                    }
    
                }
                .download-pdf{
                    margin-top: 20px;
                h4{
                  
                    margin-bottom: 10px;
                }  
            
                }
                } 
                // ============= descrption  =======
                .description {
                    width: 100%;
                    margin: 30px 0 0;
                }
            }
        }
    }
}