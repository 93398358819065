@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}






// ======   our work section start =====
.our-work-section {
	background-color: rgba(243, 247, 251, 0.5);
	.container {
		.header-description {
			width: 100%;
			@include fl-concetr-centr();
			flex-direction: column;
			text-align: center;

			h2 {
				font-size: 2.7rem;
				font-weight: 600;
				color: rgba(11, 11, 15, 1);
				margin-bottom: 16px;
			}

			p.header-descriptin {
	width: 80%;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 28px;
    color: rgb(107, 108, 116);
    margin-bottom: 80px;
			}
		}

		.works {
			width: 100%;
			@include fl-btw();
			li:last-child{
				border-right: none;
			}
			.work-sistem-about {
				display: flex;
				border-right: 1px solid black;
		
				padding: 0px 10px;
				a.work-sistems {
					text-align: center;
					cursor: pointer;
					.sistem-svg {     
						height: 106px;    
					}

					h3 {
						margin: 32px auto;
						height: 4ch;
						font-size: 1.1rem;
						font-weight: 600;
						color: rgba(11, 11, 15, 1);
					}

					p.sistems-description {
						font-size: 1rem;
						line-height: 20px;
						font-weight: 400;
						color: rgba(107, 108, 116, 1);
					}
					
				}
			

			}
	}
}
}

// ==========   our works section responsive  ============== 

@media screen and (max-width: 950px) {
    .our-work-section {
		.container {
            width: 94%;
			.header-description {
				h2 {
					font-size: 1.7rem;
				}
	
				p.header-descriptin {
                 width: 95%;
                 line-height: 22px;
				 font-size: 1rem;
				 margin-bottom: 20px;

				}
			}
	
			.works {
				width: 100%;
			     display: flex;
				 flex-wrap: wrap;
				 justify-content: center;
				.work-sistem-about {
					border-right: none;
					border-bottom: 1px solid black;
					
					a.work-sistems {
						h3 {
						    margin: 30px auto 5px;
						}
	
						p.sistems-description {
							// font-size: 1rem;
							// line-height: 20px;
							// font-weight: 400;
							// color: rgba(107, 108, 116, 1);
							margin-bottom: 7px;
						}
						// .right-icon{
						// 	color: black;
						// 	font-size: 1.5rem;
						// 	display: flex;
						// 	margin-left: 25px;
						// &:hover{
						// 	color: #013183;
						// }	
						// }
					}
	
				}
		}
	}
	}
}