@font-face {
	font-family: "Montserrat";
	src: url("/public/assets/fonts/Montserrat-Regular.ttf");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("/public/assets/fonts/Montserrat-Light.ttf");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("/public/assets/fonts/Montserrat-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("/public/assets/fonts/Montserrat-Bold.ttf");
	font-weight: bold;
	font-style: normal;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
}


*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
li{
	list-style: none;
}
a{
	text-decoration: none;
}

.container{
	width: calc(100% - 80px);
	margin: 0 auto;
	padding-top: 50px;
	padding-bottom: 22px;
}

.list-a.active svg * {
	stroke:  rgb(75, 75, 203) !important;
}

.list-a.active:hover svg * {
	stroke:  rgb(75, 75, 203) !important;
}
.list-a :hover svg * {
	stroke: #717e8b;
}

.list-a svg{
	width: 2.7rem;
	height: 2rem;
}

