

// =============  partnyor section start  =====
.partnyors-section {
	background-color: rgba(255, 255, 255, 1);
	.container {
		padding-top: 0;
		.image {
			height: 10vh;
			overflow: hidden;
			outline: 0;
			cursor: grab;
			img {       
				width: 100%;
				height: 100%;
				margin: auto;
				object-fit: scale-down;
			}
		}
	}   
}

// =============  partnyor section responsive start  =====

@media screen and (max-width:950px) {
	.partnyors-section {
		.container {
		  width: 94%;
		}   
	}
}