/* ============= partnyorlar section start  =========     */
.slider {
	position: relative;
	width: 100%;
	height: 320px;
	margin: 0 auto;
	overflow: hidden;
  }
  
  .slides {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	transition: transform 0.5s ease-in-out;
  }
  
  .slide {
	position: absolute;
	width: 400px;
	height: 320px;
	transition: all 0.5s ease-in-out;
	opacity: 0.2;
  }
  
  .slide.active {
	opacity: 1;
  }
  
  .slide img {
	display: block;
	width: 100%;
	height: 270px;
	object-fit: contain;
	border: 2px solid  white;    
  }
  
  .caption {
	width: 100%;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: rgba(11, 11, 15, 1);
	font-size: 1.2em;         
	font-weight: 600; 
  }

  .arrow{
     text-align: center; 
	 padding: 20px 0px;
	 margin-bottom: 50px;
  }
   .prev, .next{
	margin: auto 50px;
    cursor: pointer;
	background-color: rgba(1, 49, 131, 1);
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: 0.5s ease;
    color: rgba(255, 255, 255, 1);
	outline: none;
  }
  .prev:hover{
	background-color:rgba(1, 49, 131, 0.7) ;
	
  }
  .next:hover{
	background-color:rgba(1, 49, 131, 0.7) ;
  }



  /* ============= partnyorlar section responsive start  =========     */
  @media screen and (max-width:950px) {
	.slider {
		height: 250px;
	  }
	  
	  .slide {
		width: 280px;
		height: 250px;
	  }
	  .slide img {
        width: 100%;
		height: 180px;
	  }
	  .arrow{
		 margin-bottom: 50px;
		 padding: 0;
	  }
	   .prev, .next{
		margin: auto 15px;
	  }
	 
  }