@mixin fl-btwn-centr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin fl-concetr-centr {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin fl-clum {
    display: flex;
    flex-direction: column;
}

@mixin fl-btw {
    display: flex;
    justify-content: space-between;   
}

// ====  navbar section start =====

.navbar-section {
    width: 100%;
    background-color: rgba(243, 247, 251, 0.5);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 7;
    transition: 0.5s ease;

    .container {
        padding: 0;

        nav {
            @include fl-btwn-centr();
            height: 72px;

            img {
                height: 44px;
                object-fit: cover;
            }

            .pages {
                display: flex;
                align-items: center;

                li {
                    margin: auto 15px;  

                    a {
                        color: rgba(11, 11, 15, 1);
                        font-size: 13px;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }


                }
            }

            .call-email-language {
                display: flex;
                align-items: center;
                .call-email {
                    text-align: end;
                    padding-right: 20px;

                    a {
                        color: rgba(11, 11, 15, 1);
                        font-size: 15px;
                    }

                    .call {
                        font-weight: 600;
                    }

                    .email {
                        font-weight: 400;
                    }
                }

                .change-language {
                    background-color: rgba(243, 247, 251, 0.5);
                    width: 50px;
                    border: none;
                    outline: none;
                    font-size: 15px;  
                    font-weight: 600;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }

            .hamburger-menu {
                display: none;
            }
        }

        .mobil-menu-pages {
            display: none;
        }
    }
}


.nav-blue {
     background-color: rgba(1, 49, 131, 1);

    .container {
        nav {

            img {
                height: 44px;
                object-fit: cover;

            }

            .pages {

                li {

                    a {
                        color: rgba(255, 255, 255, 1);
                    }


                }
            }

            .call-email-language {
                .call-email {

                    a {
                        color: rgba(255, 255, 255, 1);

                    }
                }

                .change-language {
                    background-color: rgba(1, 49, 131, 1);
                    color: white;

                }
            }
        }

    }
}

// =============  navBAR RESPONSIVLIK  950 ==================
@media screen and (max-width: 950px) {
    .navbar-section {
        width: 100%;
        margin: 0 auto;
        .container {
            padding: 0;
            width: 94%;

            nav {
                .pages {
                    display: none;
                }

                .call-email-language {
                    align-items: center;

                    .call-email {
                        display: none;
                    }
                    // .change-language {}
                }

                .hamburger-menu {
                    display: block;
                    margin-left: 20px;

                    svg {
                        color: black;
                        font-size: 1.2rem;
                    }
                }
            }

            .mobil-menu-pages {
                display: block;
                position: absolute;
                background-color:rgb(1, 49, 131) ;
                width: 100%;
                height: 100vh;
                top: 70px;  
                left: 0;
                transition: 0.5s ease-in; 
                text-align: center;
                li:first-child{
                    margin-top: 30px;
                }
                a {
                    color: white;
                    display: inline-block;
                    margin-top: 0.5rem;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }

            .noactive {
                left: -100%;  
            }
        }
    }

    .nav-blue {
        .hamburger-menu {
            svg {
                color: white !important;
            }
        }

    }

}